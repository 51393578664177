import React, { useState } from 'react';
import { Button, Colors, Display } from '../Components/Button/Button';
import axios from 'axios';
import { SERVER_URL } from '../../Constants';
import { useHistory } from 'react-router';
import alertToast from '../../Util/ToastifyUtils';

const DeleteAccountPage = () => {
	const history = useHistory();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const onDelete = async () => {
		if (!email || !password) {
			return;
		}

		setError('');
		try {
			await axios.post<void>(
				`${SERVER_URL}/api/entity/ApplicationUserEntity/delete-account`,
				{ Email: email, Password: password },
			);

			alertToast('Successfully deleted account', 'success');
			setEmail('');
			setPassword('');
			history.replace('/');
		} catch (err :any) {
			if (err.status === 500) {
				setError('Incorrect email or password');
				return;
			}
			setError(err.message);
		}
	};
	return (
		<div className="delete-account-page">
			<h1>Delete Your Account</h1>
			<p>
				We are sorry to see you go!
				If you wish to delete your account permanently,
				please read the information below carefully.
			</p>
			<h2>What happens when you delete your account?</h2>
			<ul>
				<li>All your personal data associated with your account will be permanently erased</li>
				<li>This action cannot be undone</li>
				<li>You will need to create a new account if you wish to use our services again in the future</li>
			</ul>
			<h3>How to delete your account</h3>
			<p>To proceed with deleting your account, please enter your email and password and click
				<span style={{ fontWeight: 'bold', marginLeft: '3px' }}>&quot;Delete&quot;</span>.
			</p>
			<form>
				<div className="delete-form">
					<p className="delete-form-label">Email</p>
					<input
						className="delete-form-input"
						onChange={e => setEmail(e.target.value)}
						type="email"
					/>
					<p className="delete-form-label">Password</p>
					<input
						className="delete-form-input"
						onChange={e => setPassword(e.target.value)}
						type="password"
					/>
					<Button
						className="delete-form-button"
						colors={Colors.Primary}
						display={Display.Solid}
						onClick={() => onDelete()}
						disabled={!email || !password}
					>
						Delete
					</Button>

					<span className="delete-form-error">{error}</span>
				</div>
			</form>
		</div>
	);
};

export default DeleteAccountPage;
